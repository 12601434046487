<template>
  <div>
    <topnav></topnav>
    <div class="page-wrap product-page">
      <div class="product-top">
        <div class="product-gallery-side">
          <div class="mobile-head" v-if="isMobile">
            <h1
              v-if="$lang === 'en'"
              class="product-title serif"
            >
              {{ product.name }}
            </h1>
            <h1
              v-else-if="$lang === 'fr'"
              class="product-title serif"
            >
              {{ product.nameFrench || product.name }}
            </h1>
          </div>
          <div
            :key="$route.params.id"
            class="product-big-img"
          >
            <p>Sold</p>
            <bgl-carousel
              @change="updateGalleryIndex"
              class="product-big-img"
              :freeDrag="false"
              :nav="false"
              :dots="false"
              :loop="false"
              :margin="20"
              :items="1"
              :index="galleryIndex"
            >
              <img
                v-for="smallimage in product.imageGallery"
                :key="smallimage.id"
                :src="smallimage.imageURL"
                :alt="product.name"
              >

            </bgl-carousel>
          </div>

          <div class="product-gallery-items">
            <img
              v-for="(smallimage, i) in product.imageGallery"
              :key="smallimage.id"
              :src="smallimage.imageURL"
              :alt="product.name"
              :class="{ 'active-gallery': galleryIndex === i }"
              @click="galleryIndex = i"
            >
          </div>
        </div>
        <div class="product-txt-side">
          <div class="desktop-head" v-if="!isMobile">
            <h1
              v-if="$lang === 'en'"
              class="product-title serif"
            >
              {{ product.name }}
            </h1>
            <h1
              v-else-if="$lang === 'fr'"
              class="product-title serif"
            >
              {{ product.nameFrench || product.name }}
            </h1>
          </div>
          <div class="product-info-box">
            <p class="product-info-txt">
              <span class="product-info-txt-small">{{ $t('Sold for') }}:</span>
              <span class="product-currency">€</span>{{ product.price.toLocaleString() }}
            </p>
            <p
              v-if="product.averageBid"
              class="product-info-txt"
            >
              <span class="product-info-txt-small">{{ $t('Average bid') }}:</span>
              <span class="product-currency">€</span>{{ product.averageBid.toLocaleString() }}
            </p>
            <!-- <p
              v-if="product.interestedBuyers.length"
              class="product-info-txt"
            >
              <span class="product-info-txt-small">{{ $t('Interested Buyers') }}:</span>
              {{ product.interestedBuyers.toLocaleString() }}
            </p> -->
            <router-link
              class="product-btn serif"
              to="/form"
            >
              <p> {{ $t('Start here') }}</p>
              <img
                src="../assets//arrow.svg"
                alt=""
              >
            </router-link>
          </div>
          <div class="product-long-txt">
            <h3 v-if="product.description || product.descriptionFrench">Description</h3>
            <div
              v-if="$lang === 'en'"
              v-html="product.description"
            />
            <div
              v-else-if="$lang === 'fr'"
              v-html="product.descriptionFrench || product.description"
            />
            <div
              v-if="$lang === 'en'"
              v-html="product.attributes"
            />
            <div
              v-else-if="$lang === 'fr'"
              v-html="product.attributesFrench || product.attributes"
            />
            <h3 v-if="product.additionalInformation || product.additionalInformationFrench">{{ $t('Additional Information') }}</h3>
            <div
              v-if="$lang === 'en'"
              v-html="product.additionalInformation"
            />
            <div
              v-else-if="$lang === 'fr'"
              v-html="product.additionalInformationFrench || product.additionalInformation"
            />
          </div>

        </div>
      </div>
      <h3
        :key="$route.params.id + 'other'"
        class="product-title title-bottom serif"
      >
        {{ $t('Other Products') }}
      </h3>
      <bgl-carousel
        :refresh="$route.params.id"
        class="prof-carousel"
        :nav="false"
        :dots="false"
        :loop="true"
        :margin="20"
        :autoHeight="true"
        :responsive="{
          0: {
            items: 1,
          },
          500: {
            items: 2,
          },
          960: {
            items: 3,
          },
          1340: {
            items: 4,

          }
        }"
      >
        <router-link
          v-for=" slide in otherProducts"
          :key="slide._id"
          :to="`/product/${slide._id}`"
          class="carousel-box"
        >
          <div class="carousel-box-top">
            <p class="carousel-box-sold">{{ $t('Sold') }}</p>
            <!-- <p
              v-if="interestedBuyers"
              class="carousel-box-buyers"
            >{{ slide.interestedBuyers }} {{ $t('Interested Buyers') }}</p> -->
            <img
              :src="slide.imageGallery[0].imageURL"
              :alt="slide.title"
            >
          </div>

          <div class="carousel-box-bot">
            <p class="carousel-box-price"><span>€</span>{{ slide.price.toLocaleString() }}</p>
            <p v-if="$lang === 'en'">{{ slide.sliderText }}</p>
            <p v-else-if="$lang === 'fr'">{{ slide.sliderTextFrench || slide.sliderText }}</p>
          </div>
        </router-link>
        <template #next>
          <img
            src="../assets/car-arrow-1.svg"
            class="car-arrow"
          />
        </template>
        <template #prev>
          <img
            src="../assets/car-arrow-2.svg"
            class="car-arrow"
          />
        </template>
      </bgl-carousel>

      <div class="mobile-slider">
        <router-link
          v-for=" slide in otherProducts"
          :key="slide._id"
          :to="`/product/${slide._id}`"
          class="carousel-box"
        >
          <div class="carousel-box-top">
            <p class="carousel-box-sold">{{ $t('Sold') }}</p>
            <img
              :src="slide.imageGallery[0].imageURL"
              :alt="slide.title"
            >
          </div>

          <div class="carousel-box-bot">
            <p class="carousel-box-price"><span>€</span>{{ slide.price.toLocaleString() }}</p>
            <p v-if="$lang === 'en'">{{ slide.sliderText }}</p>
            <p v-else-if="$lang === 'fr'">{{ slide.sliderTextFrench || slide.sliderText }}</p>
          </div>
        </router-link>
      </div>
    </div>

    <botfooter></botfooter>
  </div>
</template>


<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import { carousel as cData } from '@/data/localData.json'
import BglCarousel from "@/components/bglCarousel.vue";

export default {
  components: {
    Topnav,
    Botfooter,
    BglCarousel
},
  name: "Product",
  data() {
    return {
      galleryIndex: 0,
      isMobile: false,
    }
  },
  mounted(){
    if (window.innerWidth < 900) {
      this.isMobile = true;
    }
  },
  methods: {
    updateGalleryIndex(event) {
      const { item } = event;
      console.log(item)
      if (item.index !== this.galleryIndex) this.galleryIndex = item.index;
    }
  },
  computed: {
    $local() {
      return { carousel: cData }
    },
    otherProducts() {
      return this.$local?.carousel?.filter(s => !s.draft && s._id && s._id !== this.$route.params.id)
    },
    product() {
      return this.$local?.carousel?.find?.(b => b._id === this.$route.params.id)
    }
  }
};
</script>

<style>
.product-carousel-img {
  min-height: 500px;
}

.product-carousel-img img {
  min-height: 500px;
}

.car-arrow {
  width: 50px;
  transition: all 200ms ease;
  cursor: pointer;
}

.car-arrow:hover {
  filter: brightness(60%);
}

.car-arrow:active {
  filter: brightness(30%);
}

.active-gallery {
  border: 2px solid #827C78;
}

.product-page {
  width: 90%;
}

.product-top {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.mobile-head {
  display: none;
}

.product-gallery-side {
  flex: 0 0 570px;
  max-width: 570px;
}

.product-big-img {
  position: relative;
}

.product-big-img img {
  width: 100%;
  height: 570px;
  object-fit: cover;
  filter: brightness(98%);
}

.product-big-img p {
  position: absolute;
  background: #827C78;
  color: white;
  text-transform: uppercase;
  top: 20px;
  left: 20px;
  margin: 0;
  padding: 1px 10px;
  z-index: 3;
}

.product-gallery-items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.product-gallery-items img {
  height: 120px;
  cursor: pointer;
  width: 120px;
  object-fit: cover;
  transition: all 200ms ease;
}

.product-gallery-items img:hover {
  filter: brightness(90%);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.product-gallery-items img:active {
  filter: brightness(80%);
}

.product-txt-side {
  flex: 0 1 810px;
  max-width: 810px;
}

.product-title {
  font-size: 70px;
  margin-top: 0;
  line-height: 1.3;
  min-height: 388px;
}

.product-title.title-bottom {
  min-height: 0;
  margin-bottom: 5px;
  margin-top: 20px;
}

.product-info-box {
  background: #F1F0EE;
  display: flex;
  min-height: 130px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product-info-txt {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.2;
  margin: 30px;
  flex: 1 0 auto;
}

.product-info-txt span {
  font-weight: 400;
}

.product-info-txt-small {
  display: block;
  font-size: 20px;
}

.product-currency {
  font-size: 26px;
  color: #BEB6AF;
}

.product-btn {
  background: #827C78;
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
  height: 100%;
  transition: all 200ms ease;
  font-style: italic;
  font-size: 30px;
  flex: 1 0 auto;
  justify-content: center;
}

.product-btn:hover {
  filter: brightness(90%);
}

.product-btn:active {
  filter: brightness(80%);
}

.product-btn img {
  height: 30px;
}

.product-long-txt {
  margin-bottom: 30px;
}

.product-long-txt h3,
.product-long-txt h2,
.product-long-txt h1 {
  margin-top: 40px;
  margin-bottom: 0;
  color: black;
  font-size: 30px;
  font-weight: 700;
}

.product-long-txt p {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 1.6;
}

.product-long-txt p b,
.product-long-txt p strong {
  color: black;
  font-weight: 700;
}

@media screen and (max-width: 1200px) {
  .product-gallery-side {
    flex: 0 0 420px;
    max-width: 420px;
  }

  .product-big-img img {
    width: 100%;
    height: 420px;
  }
}

@media screen and (max-width: 900px) {
  .product-big-img p {
    top: 10px;
    left: 10px;
  }

  .desktop-head {
    display: none;
  }

  .mobile-head {
    display: block;
  }

  .product-top {
    display: block;
  }

  .product-gallery-side {
    max-width: 100%;
  }

  .product-big-img img {
    width: 100%;
    max-height: 420px;
    height: auto;
  }

  .product-gallery-items {
    gap: 10px;
    margin-top: 10px;
  }

  .product-gallery-items img {
    height: 50px;
    width: 50px;
  }

  .product-title {
    font-size: 40px;
    margin-top: 0;
    line-height: 1.2;
    min-height: 0;
  }

  .product-info-box {
    margin-top: 30px;
  }

  .product-info-txt {
    font-size: 30px;
    margin: 10px 20px;
    flex: 1 0 auto;
  }

  .product-info-txt-small {
    font-size: 16px;
  }

  .product-currency {
    font-size: 16px;
  }

  .product-btn {
    padding: 0px 10px;
    width: 100%;
    font-size: 22px;
  }

  .product-long-txt h3,
  .product-long-txt h2,
  .product-long-txt h1 {
    margin-top: 30px;
    font-size: 24px;
  }
}

@media screen and (max-width: 600px) {
  .product-gallery-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));

  }

  .product-gallery-items img {
    width: 100%;
  }
}
</style>

